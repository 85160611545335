import React, { Component } from 'react';
import styled from 'styled-components';
import { FaLinkedin } from 'react-icons/fa';
import { withPrefix, graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import {
  COLOR_GREEN,
  COLOR_WHITE,
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  COLOR_GRAY,
  COLOR_TEXT_GRAY,
  COLOR_LIGHT_GRAY,
  screen,
  COLOR_RED,
  COLOR_PITCH,
  emailRegX,
  phoneNumberRegX,
  MAIN_HEADING_FONT,
  Button,
  StyledA,
} from '../components/common/variables';
import Layout from '../components/layout';
import { encode } from '../components/common/functions';
import loaderIcon from '../images/three-dots.svg';

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.largeXL} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.largeXL} {
      display: none;
    }
  }
  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  .contact {
    max-width: ${MAX_WIDTH};
    margin: 45px auto 0 auto;
    padding: 0 35px;
    @media ${screen.largeXL} {
      margin: 60px auto 0 auto;
    }

    &__page-title {
      font-size: 1.8rem;
      font-weight: 400;
      margin: 0;
      color: ${COLOR_GRAY};
      @media ${screen.medium} {
        margin: 0 0 10px 0;
        font-size: 2.1rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.4rem;
      }
    }

    &__page-description {
      font-size: 1rem;
      color: ${COLOR_TEXT_GRAY};
      margin: 20px auto 0 auto;
      @media ${screen.medium} {
        margin: 30px auto 0 auto;
        font-size: 1.1rem;
      }
    }

    &__form-details {
      display: block;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 20px;
      @media ${screen.large} {
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
      }
    }

    &__form-container {
      max-width: 700px;
      @media ${screen.large} {
        width: 700px;
        margin-right: 20px;
      }
      @media ${screen.largeXXL} {
        margin-right: 0;
      }
    }

    .send-button {
      width: 100%;
      @media ${screen.large} {
        width: unset;
        display: inline;
      }
    }

    .send-loader {
      width: 40px;
      margin: 20px auto 0 auto;
      display: block;
      @media ${screen.large} {
        width: 52px;
        margin: 0 0 0 20px;
        vertical-align: middle;
        display: inline;
      }
    }

    &__input-container {
      display: flex;
      margin-bottom: 18px;
      @media ${screen.large} {
        justify-content: space-between;
        margin-bottom: 24px;
      }

      label {
        color: ${COLOR_GREEN};
        font-weight: 400;
        font-size: 1.1rem;
        position: relative;
        display: none;
        @media ${screen.large} {
          display: block;
        }
      }

      &__textarea-container {
        width: 100%;
        display: block;
        @media ${screen.medium} {
          margin-bottom: 24px;
          width: 410px;
        }
        @media ${screen.large} {
          width: 430px;
        }
      }

      textarea {
        resize: none;
        width: 100%;
        height: 125px;
        border: 0;
        margin-bottom: 18px;
        display: block;
        outline: none;
        border-radius: 5px;
        padding: 11px;
        background-color: ${COLOR_LIGHT_GRAY};
        box-sizing: border-box;
        @media ${screen.medium} {
          margin-bottom: 24px;
          padding: 13px;
        }

        ::placeholder {
          color: ${COLOR_TEXT_GRAY};
          font-size: 1rem;
          @media ${screen.large} {
            color: ${COLOR_LIGHT_GRAY};
          }
        }
      }
    }

    &__inputs {
      color: #3a3a3a;
      width: 100%;
      border: 0;
      outline: none;
      border-radius: 5px;
      padding: 11px;
      background-color: ${COLOR_LIGHT_GRAY};
      box-sizing: border-box;
      @media ${screen.medium} {
        padding: 13px;
        width: 410px;
      }
      @media ${screen.large} {
        width: 430px;
      }
      ::placeholder {
        color: ${COLOR_TEXT_GRAY};
        font-size: 1rem;
        @media ${screen.large} {
          color: ${COLOR_LIGHT_GRAY};
        }
      }
    }

    &__inputs--error {
      ::placeholder {
        color: ${COLOR_RED};
        @media ${screen.large} {
          color: ${COLOR_PITCH};
        }
      }
    }

    &__contact-details {
      background: ${COLOR_GREEN};
      padding: 45px 30px 35px 30px;
      display: none;
      @media ${screen.largeXL} {
        display: block;
      }
      svg {
        font-size: 1.4rem;
        border-radius: 8px;
        margin-bottom: -5px;
        color: ${COLOR_WHITE};
      }
      p {
        color: ${COLOR_LIGHT_GRAY};
        font-size: 1.1rem;
        display: inline-block;
      }
      a {
        padding-bottom: 1px;
        margin-bottom: 18px;
        font-weight: 400;
        display: table;
        transition: all 0.2s ease-out;

        &:hover {
          /* text-decoration: underline; */
          box-shadow: 0 4px 0 -2.7px ${COLOR_WHITE} !important;
        }
      }
      .linkedin-link {
        display: inline-block;
      }
    }

    &__required-text {
      display: block;
      color: ${COLOR_RED};
      position: absolute;
      font-size: 0.8rem;
      margin-top: 3px;
      font-weight: 100;
    }

    &__optional-text {
      display: block;
      color: ${COLOR_TEXT_GRAY};
      position: absolute;
      font-size: 0.8rem;
      margin-top: 3px;
      font-weight: 100;
    }

    &__fix-warning {
      color: ${COLOR_RED};
      font-size: 1rem;
      line-height: 1.4;
      margin-top: 9px;
      text-align: center;
      @media ${screen.large} {
        margin-top: 15px;
        text-align: left;
      }
    }

    &__success-msg {
      color: ${COLOR_GREEN};
      font-size: 1rem;
      line-height: 1.4;
      margin-top: 9px;
      text-align: center;
      @media ${screen.large} {
        margin-top: 15px;
        text-align: left;
      }
    }
    /* START - Element styles that appears only on mobile/tablet screen */
    &__mobile-call-email-btns {
      width: calc(100vw - 70px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1rem 0rem;
      .call,
      .email {
        width: 47%;
        max-width: calc(50vw - 90px);
        margin: 3px;
        text-align: center;
        padding: 0.5rem 1.3rem 0.3rem 1.3rem;
      }
      @media ${screen.large} {
        justify-content: flex-start;
        margin-top: 20px;
        text-align: unset;
        margin-bottom: 40px;
        display: flex;
        .call,
        .email {
          width: unset;
        }
        a {
          display: inline-block;
        }
        .call {
          margin-right: 7px;
        }
        .email {
          margin-left: 7px;
        }
      }
      @media ${screen.largeXL} {
        display: none;
      }
    }

    &__mobile-visit-us {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.3;
      color: ${COLOR_GRAY};
      margin-top: 40px;
      ${MAIN_HEADING_FONT}
      @media ${screen.medium} {
        font-size: 1.4rem;
        margin: 0;
      }
      @media ${screen.largeXL} {
        display: none;
      }
      a {
        color: ${COLOR_GREEN};
      }
    }
    /* END - Element styles that appears only on mobile/tablet screen */
  }
`;

const MapWrapper = styled.div`
  display: flex;
  max-width: ${MAX_WIDTH_SITE};
  margin: 25px auto 0 auto;
  @media ${screen.largeXL} {
    margin: 90px auto 0 auto;
  }
  iframe {
    width: 100%;
    height: 260px;
    display: block;
    @media ${screen.largeXL} {
      margin: 0;
      width: 65%;
      height: inherit;
      padding: 0;
    }
  }
  .image {
    width: 680px;
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const errorInputStyle = {
  border: `1px ${COLOR_RED} solid`,
  backgroundColor: `${COLOR_PITCH}`,
};

const errorTextStyle = {
  color: `${COLOR_RED}`,
};

class ContactPage extends Component {
  state = {
    name: '',
    company: '',
    email: '',
    number: '',
    deliverySuburb: '',
    message: '',
    fixWarning: false,
    successMsg: false,
    errorName: false,
    errorEmail: false,
    errorNumber: false,
    loader: false,
  };

  handleInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, company, email, number, deliverySuburb, message } =
      this.state;

    if (!name.length) {
      this.setState({ errorName: true, fixWarning: true });
    } else {
      this.setState({ errorName: false });
    }

    if (!email.length || !emailRegX.test(email)) {
      this.setState({ errorEmail: true, fixWarning: true });
    } else {
      this.setState({ errorEmail: false });
    }

    if (!number.length || !phoneNumberRegX.test(number)) {
      this.setState({ errorNumber: true, fixWarning: true });
    } else {
      this.setState({ errorNumber: false });
    }

    if (
      name.length &&
      email.length &&
      emailRegX.test(email) &&
      number.length &&
      phoneNumberRegX.test(number)
    ) {
      // if all the values are valid do the action here
      this.setState({ loader: true });
      const inputs = {
        person_name: name,
        company_name: company,
        email: email,
        contact_number: number,
        delivery_suburb: deliverySuburb,
        message: message,
      };
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'General Enquiry', ...inputs }),
      })
        .then(() => {
          this.setState({
            name: '',
            company: '',
            email: '',
            number: '',
            deliverySuburb: '',
            message: '',
            fixWarning: false,
            successMsg: true,
            loader: false,
          });
        })
        .catch((error) => alert(error));
    } else {
      this.setState({ successMsg: false });
    }
  };

  render() {
    const {
      name,
      email,
      number,
      company,
      deliverySuburb,
      message,
      loader,
      errorName,
      errorNumber,
      errorEmail,
      fixWarning,
      successMsg,
    } = this.state;
    const banner =
      this.props.data.content.acfContactPage.imageBanner.localFile
        .childImageSharp.gatsbyImageData;
    const bannerSmall =
      this.props.data.smallScreenBanner.acfContactPage.imageBanner.localFile
        .childImageSharp.gatsbyImageData;
    const emailAddress = 'info@sourceseparationsystems.com.au';
    const phoneNumber = '1300 739 913';
    const linkedIn =
      'https://www.linkedin.com/company/source-separation-systems/about/';

    const { acfContactPage } = this.props.data.content;
    const pageTitle =
      (acfContactPage.seo && acfContactPage.seo.page_title) || 'Contact';
    const pageDescription =
      acfContactPage.seo && acfContactPage.seo.description;

    return (
      <Layout>
        <SEO
          title={pageTitle}
          description={pageDescription}
          image={withPrefix('/og_images/contact.jpeg')}
        />

        <Banner>
          <GatsbyImage
            className="large-banner"
            alt={'Get In Touch - Source Separation Systems'}
            image={banner}
          />

          <div className="small-banner">
            <GatsbyImage
              alt={'Get In Touch - Source Separation Systems'}
              image={bannerSmall}
            />
          </div>
        </Banner>
        <Wrapper>
          <div className="contact">
            <h1 className="contact__page-title">Contact us</h1>
            <p className="contact__page-description">
              We love talking about sustainability!
            </p>
            <p className="contact__page-description">
              Contact our expert team to learn more about our best practice
              solutions, or to
              <br />
              discuss how we can partner with you to meet your sustainability
              goals.
            </p>
            <div className="contact__mobile-call-email-btns">
              {/* ---> this will only appear on mobile/tablet screen */}
              <StyledA className="call" href={`tel:${phoneNumber}`}>
                Call us
              </StyledA>
              <StyledA
                className="email"
                transparent="true"
                href="mailto:info@sourceseparationsystems.com.au"
              >
                Email us
              </StyledA>
            </div>
            <p className="contact__page-description">
              Give us a call, shoot through an email or fill in the form below
              to get in touch.
            </p>
            <div className="contact__form-details">
              <div className="contact__form-container">
                <form
                  onSubmit={this.handleSubmit}
                  name="General Enquiry"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <div className="contact__input-container">
                    <label style={errorName ? errorTextStyle : null}>
                      Your name
                      {errorName ? (
                        <span className="contact__required-text">Required</span>
                      ) : null}
                    </label>
                    <input
                      className={
                        errorName
                          ? 'contact__inputs contact__inputs--error'
                          : 'contact__inputs'
                      }
                      onChange={this.handleInput}
                      value={name}
                      id="name"
                      type="text"
                      placeholder="Your name*"
                      style={errorName ? errorInputStyle : null}
                      name="person_name"
                    />
                  </div>
                  <div className="contact__input-container">
                    <label>Company name</label>
                    <input
                      className="contact__inputs"
                      onChange={this.handleInput}
                      value={company}
                      id="company"
                      type="text"
                      placeholder="Company name"
                      name="company_name"
                    />
                  </div>
                  <div className="contact__input-container">
                    <label style={errorEmail ? errorTextStyle : null}>
                      Email
                      {errorEmail ? (
                        <span className="contact__required-text">Required</span>
                      ) : null}
                    </label>
                    <input
                      className={
                        errorEmail
                          ? 'contact__inputs contact__inputs--error'
                          : 'contact__inputs'
                      }
                      onChange={this.handleInput}
                      value={email}
                      id="email"
                      type="text"
                      placeholder="Email*"
                      style={errorEmail ? errorInputStyle : null}
                      name="email"
                    />
                  </div>
                  <div className="contact__input-container">
                    <label style={errorNumber ? errorTextStyle : null}>
                      Contact number
                      {errorNumber ? (
                        <span className="contact__required-text">Required</span>
                      ) : null}
                    </label>
                    <input
                      className={
                        errorNumber
                          ? 'contact__inputs contact__inputs--error'
                          : 'contact__inputs'
                      }
                      onChange={this.handleInput}
                      value={number}
                      id="number"
                      type="text"
                      placeholder="Contact number*"
                      style={errorNumber ? errorInputStyle : null}
                      name="contact_number"
                    />
                  </div>
                  <div className="contact__input-container">
                    <label>
                      Delivery suburb
                      <span className="contact__optional-text">
                        If requesting a quote
                      </span>
                    </label>
                    <input
                      className="contact__inputs"
                      onChange={this.handleInput}
                      value={deliverySuburb}
                      id="deliverySuburb"
                      type="text"
                      placeholder="Delivery suburb"
                      name="delivery_suburb"
                    />
                  </div>
                  <div className="contact__input-container">
                    <label>Message</label>
                    <span className="contact__input-container__textarea-container">
                      <textarea
                        onChange={this.handleInput}
                        value={message}
                        id="message"
                        data-gramm_editor="false"
                        placeholder="Message"
                        name="message"
                      />
                      <Button className="send-button">Send</Button>
                      {loader ? (
                        <img
                          className="send-loader"
                          src={loaderIcon}
                          alt="Loading..."
                        />
                      ) : null}
                      {fixWarning ? (
                        <p className="contact__fix-warning">Required fields</p>
                      ) : null}
                      {successMsg ? (
                        <p className="contact__success-msg">
                          Thank you for contacting us! Your message has been
                          successfully sent and someone from our team will be in
                          touch shortly.
                        </p>
                      ) : null}
                    </span>
                  </div>
                </form>
              </div>
              <div className="contact__contact-details">
                <p>Talk directly to a human being</p>
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                <p>Email us</p>
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                <p>Visit us</p>
                <a
                  href="https://goo.gl/maps/AwRe8mgd3A1KFjT18"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  108 Munibung Road
                  <br />
                  Boolaroo, NSW 2284
                </a>
                <p>
                  <a
                    className="linkedin-link"
                    href={linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin />
                  </a>{' '}
                  Contact us via{' '}
                </p>{' '}
                <a
                  className="linkedin-link"
                  href={linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </div>
              <div className="contact__mobile-visit-us">
                {/* ---> this will only appear on mobile/tablet screen */}
                <p>You can also visit us at</p>
                <a
                  href="https://goo.gl/maps/AwRe8mgd3A1KFjT18"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  108 Munibung Road
                  <br />
                  Boolaroo, NSW 2284
                </a>
              </div>
            </div>
          </div>
          <MapWrapper>
            <iframe
              title="Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.4450749638518!2d151.6331922153609!3d-32.939256778844495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b733da96fa56a73%3A0xf664bcdc605d3c4d!2sSource+Separation+Systems!5e0!3m2!1sen!2sau!4v1554773084597!5m2!1sen!2sau"
              frameBorder="0"
              allowFullScreen
            />
            <div className="image">
              <StaticImage
                src="../images/source-separation-systems-contact-us.jpg"
                alt="Contact Source Separation Systems"
              />
            </div>
          </MapWrapper>
        </Wrapper>
      </Layout>
    );
  }
}

export default ContactPage;

export const contactData = graphql`
  {
    content: wpPage(id: { eq: "cG9zdDoxMQ==" }) {
      id
      title
      acfContactPage {
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    smallScreenBanner: wpPage(id: { eq: "cG9zdDoxMQ==" }) {
      id
      title
      acfContactPage {
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 624)
            }
          }
        }
      }
    }
  }
`;
